export const socialLinks = [
	// {
	// 	id: 1,
	// 	name: 'Website',
	// 	icon: 'globe',
	// 	url: 'https://www.stoman.me/',
	// },
	// {
	// 	id: 2,
	// 	name: 'GitHub',
	// 	icon: 'github',
	// 	url: 'https://github.com/realstoman',
	// },
	// {
	// 	id: 3,
	// 	name: 'Twitter',
	// 	icon: 'twitter',
	// 	url: 'https://twitter.com/realstoman',
	// },
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/ryan-mcguire-82a8b3113/',
	},
	// {
	// 	id: 5,
	// 	name: 'YouTube',
	// 	icon: 'youtube',
	// 	url: 'https://www.youtube.com/c/realstoman',
	// },
];
